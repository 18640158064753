import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as server from "Config/server";
import { useToast } from "@chakra-ui/react";
import Loading from "Shared/Loading";

const PayRedirect = () => {
  const navigate = useNavigate();
  const Toast = useToast();
  const search = new URL(window.location.href);
  const merchant_uid = search.searchParams.get("merchant_uid");
  const imp_uid = search.searchParams.get("imp_uid");
  const imp_success = search.searchParams.get("imp_success");
  const error_msg = search.searchParams.get("error_msg");
  const userdata = JSON.parse(sessionStorage.getItem("UserData"));

  const [isLoading, SetLoading] = useState(false);

  const redirect = async () => {
    SetLoading(true);

    if (imp_success === "false") {
      SetLoading(false);
      navigate("/token");

      Toast({
        position: "top-right",
        title: "error",
        description: ` ${error_msg}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    if (imp_success === "true") {
      const plan = merchant_uid.split("_")[1];

      await axios({
        method: "post",
        url: `${server.SERVER_URL}/user/pay`,
        headers: { Authorization: "Bearer " + userdata.token },
        data: {
          service: "kakao",
          imp_uid: imp_uid,
          merchant_uid: merchant_uid,
          plan: parseInt(plan),
        },
      }).catch((error) => {
        SetLoading(false);
        navigate("/fail");
        Toast({
          position: "top-right",
          title: `${error.response.status} Error`,
          description: ` ${error.response.data.message}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });

      SetLoading(false);
      navigate("/paydone");

      Toast({
        position: "top-right",
        title: "success",
        description: `결제 성공!`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    redirect();
  }, [redirect]);

  return <div>{isLoading && <Loading />}</div>;
};

export default PayRedirect;
