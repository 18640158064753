import React from "react";
import styled from "styled-components";

const Btn = styled.button`
  width: ${(props) => props.width};
  background-color: ${(props) => props.color};
  border: 1px solid #444;
  color: ${(props) => props.font};
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  padding: 8px 15px;
  word-break: keep-all;
  transition: all 300ms ease-in-out;

  &:hover {
    background-color: ${(props) => props.hover};
  }
`;
//버튼 안에 들어갈 문구, 색, hover 시 색 지정.
const Button = ({ children, color, hover, font, width }) => {
  return (
    <Btn color={color} font={font} hover={hover} width={width}>
      {children}
    </Btn>
  );
};

export default Button;
