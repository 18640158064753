import React from "react";
import { Link } from "react-router-dom";
import { Box, CloseButton, Flex, Text, Avatar, Button } from "@chakra-ui/react";
import { LogBtn } from "Styles/ComponentStyled";
import styled from "styled-components";
import { authService } from "Config/FirebaseConfig";

const SubText = styled(Text)` 
  padding: 3px 10px;
  cursor: pointer;
  &:hover {
    background-color: #000;
    border-radius: 10px;
    color: #fff;
    transition: all 300ms ease-in-out;
  }
`

const Drawer = ({ onClose, userdata }) => {

  const { Kakao } = window;

  const SignOut = async () => {
    if (userdata.provider === "kakao") {
      Kakao.Auth.logout(() => {
        sessionStorage.clear();
        window.location.reload();
      });
    } else {
      sessionStorage.clear();
      await authService.signOut();
      window.location.reload();
    }
  };
  return (
    <Box
      bg="#ededed"
      boxShadow="none"
      borderRight="1px solid #444"
      pb="2em"
      zIndex={15}
      position="absolute"
      left={0}
      top={0}
      w={{ base: "calc(100% - 50px)", sm: "300px" }}
      h="100vh"
      overflow="auto"
      className="drawerAniLeft "
    >
      <Flex justify={'flex-end'} p="10px">
        <CloseButton onClick={onClose} />
      </Flex>
      <Flex p='10px 20px'>
        {userdata && userdata.isLogin ? (
          <Flex direction={'column'} w='100%' gap='25px' mb='20px'>
            <Flex align='center'>
              <Link to="/mypage">
                <Avatar
                  bg="#fff"
                  border="1px solid #444"
                  name="username"
                  src={userdata.userImage ? userdata.userImage : "/user.png"}
                />
              </Link>
              <Text ml='15px'>{userdata.userName ? userdata.userName : '드로잉젤 유저'}</Text>
            </Flex>
            <Button colorScheme={'red'} w='100%' onClick={SignOut}>로그아웃</Button>
          </Flex>
        ) : (
          <Link to="/login">
            <LogBtn>로그인</LogBtn>
          </Link>
        )}
      </Flex>
      <Box p="10px 20px 10px 15px">

        <Text fontWeight='600' fontSize='xl'>🎨 AI 서비스</Text>
        <Flex direction={'column'} gap='10px' m='15px 0 30px'>
          <SubText><Link to='/'>생성 Draw</Link></SubText>
          {/*<SubText><Link to='/transform'>변형 Transform</Link></SubText>*/}
          {/*<SubText><Link to="/edit">편집 Generator</Link></SubText>*/}
          <SubText>
            <button onClick={() => alert("준비중입니다.")}>
              변형 Transform
            </button>
          </SubText>
          <SubText>
            <button onClick={() => alert("준비중입니다.")}>
              편집 Generator
            </button>
          </SubText>
        </Flex>
        <Text fontWeight="600" fontSize="xl" _hover={{
          backgroundColor: "#000",
          borderRadius: '10px', color: '#fff', transition: 'all 300ms ease-in-out', padding: '3px'
        }}><Link to='/token'>💳 결제</Link></Text>
      </Box>
    </Box>
  );
};

export default Drawer;
