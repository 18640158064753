import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Button from "Common/Button";
import { Box, Flex } from "@chakra-ui/react";
import styled from "styled-components";
import "Styles/Main.scss";

import Loading from "Shared/Loading";
import * as server from "Config/server";
import TingelSaveData from "./TingelSaveData";
import Layout from "Common/Layout";

const TingNoContent = styled(Flex)`
  > div {
    text-align: center;

    > img {
      width: 300px;
      opacity: 0.5;

      @media screen and (max-width: 680px) {
        width: 200px;
      }
    }

    > p {
      padding: 40px 0;
      font-size: 1.4rem;
      font-weight: 600;
      word-break: keep-all;
    }
  }
`;

const TingelBox = () => {
  const userdata = JSON.parse(sessionStorage.getItem("UserData"));

  const [isLoading, SetLoading] = useState(false);
  const [savedData, setSavedData] = useState("");

  const getSaveContent = async () => {
    SetLoading(true);
    const response = await axios({
      method: "get",
      url: `${server.SERVER_URL}/user/prompt/save`,
      headers: { Authorization: "Bearer " + userdata.token },
    }).catch(() => {
      SetLoading(false);
    });

    const data = response.data.data; //array
    if (data.length === 0) {
      return;
    }

    if (data.length > 0) {
      setSavedData(data);
    }
    SetLoading(false);
  };

  useEffect(() => {
    getSaveContent();
  }, []);

  return (
    <Layout>
      {isLoading && <Loading />}
      <Flex
        justify={"center"}
        align="center"
        p={{ base: "5em 2em", md: "8em 5em" }}
        bg="#f3f2f7"
      >
        {savedData && <TingelSaveData savedata={savedData} />}
        {!savedData && (
          <TingNoContent direction={"column"} justify="center" align="center">
            <Box>
              <p>보관된 내용이 없습니다!</p>
            </Box>
            <Link to="/">
              <Button color="#b1b5e6" font="#444" hover="#ff9300">
                서비스 이용하러 가기
              </Button>
            </Link>
          </TingNoContent>
        )}
      </Flex>
    </Layout>
  );
};

export default TingelBox;
