import styled from "styled-components";
import { Flex, Text, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";

//중복되는 메인 스타일들
export const NavUl = styled.ul`
  list-style: none;
  text-align: ${(props) => (props.center ? "center" : "left")};
  cursor: pointer;
  color: #444;
  font-size: 15px;

  > a > li {
    margin: 8px 0;

    &:hover {
      color: #4046b7;
      font-weight: 600;
    }
  }
`;
export const MobileToggleMenu = styled.div`
  padding: 15px 0;
  border-bottom: 1px solid #444;
`;

export const InfoBox = styled(Flex)`
  align-items: center;
  padding: 15px;
  gap: 20px;

  > h4 {
    font-weight: 600;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: baseline;
    width: 100%;

    > p {
      width: 100%;
    }
  }
`;

export const MyPageBtns = styled(Flex)`
  > a {
    width: 100%;
  }
`;

export const CheckSubs = styled.div`
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 20px;
  word-break: keep-all;
`;

export const LabelText = styled(Text)`
  font-size: 18px;
  font-weight: 600;
  margin-bottom : 15px;
`


export const DoneContainer = styled(Flex)`
  width: 100%;
  height: 100vh;

  @media screen and (max-width: 768px) {
    height: 100%;
    padding: 10em 1em;
  }
`;

export const DoneButton = styled(Flex)`
  text-align: center;

  > button:first-child {
    background-color: #372874;
    border: 1px solid #372874;
    color: #fff;
    font-size: 20px;
    margin-right: 15px;
    padding: 5px 25px;
    cursor: pointer;
  }

  > button:nth-child(2) {
    background-color: #ffce1f;
    border: 1px solid #ffce1f;
    color: #444;
    font-size: 20px;
    padding: 5px 25px;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    gap: 10px;
    padding-top: 20px;

    > button {
      width: 100%;
    }

    > button:first-child {
      margin: 0;
    }
  }
`;

export const FormInputs = styled.div`
  max-width: 400px;
  width: 100%;

  > p {
    font-size: 13px;
  }

  > input {
    width: 100%;
    padding: 10px 5px;
    font-size: 1rem;
    border: 1px solid #051619;
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

export const RegTitle = styled(Flex)`
  > h3 {
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
  }

  > h4 {
    font-size: 15px;
    font-weight: 400;
  }

  > div > h5 {
    font-size: 15px;
    font-weight: 600;
  }
`;

export const LoginLink = styled.div`
  font-size: 14px;
  text-align: center;

  > a {
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const  LogBtn = styled(Button)`
background-color:#fff;
color:#000;
border: 2px solid #000;

&:hover {
  background-color:#000;
  color:#fff;
  transition: all 300ms ease-in-out;
}
`

export const EbookPara = styled.p`
  max-width: 650px;
  width: 100%;
  word-break: keep-all;
  margin-bottom: 15px;
  font-size: 17px;
  line-height: 32px;

  > span {
    font-weight: 600;
  }
`;

export const GoShopBtn = styled.button`
  width: 120px;
  background-color: ${(props) => props.color};
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  padding: 8px 15px;
  word-break: keep-all;
  transition: all 300ms ease-in-out;

  &:hover {
    background-color: ${(props) => props.hover};
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const BookInfo = styled(Flex)`
  margin-bottom: 20px;
  word-break: keep-all;
  line-height: 30px;

  > div:first-child {
    max-width: 300px;
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    border-right: 1px solid #e3e3e3;
    margin-right: 20px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    > div:first-child {
      max-width: none;
      border-right: 0;
      margin-right: 0;
      border-bottom: 1px solid #e3e3e3;
      padding-bottom: 5px;
      margin-bottom: 20px;
    }
  }
`;

export const LyricBtn = styled.button`
  width: 100%;
  background-color: #372874;
  border: 1px solid #444;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  padding: 8px 15px;
  word-break: keep-all;
  transition: all 300ms ease-in-out;

  &:hover {
    background-color: #4046b7;
  }
`;

export const NewLink = styled(Link)`
  display: flex;
  gap: 4px;
  align-items: center;

  > p {
    background-color: #ffc34e;
    color: #000;
    border-radius: 5px;
    font-size: 0.5rem;
    padding: 1px 3px;
    font-weight: 600;

    > span {
      animation: twinkle 1s 20;
      padding: 0;
    }
  }

  @keyframes twinkle {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
