//결제 실패시
import React from "react";
import { Link } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import styled from "styled-components";
import Layout from "Common/Layout";

const ErrorContainer = styled(Flex)`
  width: 100%;
  height: 100vh;

  @media screen and (max-width: 768px) {
    height: 100%;
    padding: 10em 1em;
  }
`;

const ErrorPage = styled(Box)`
  padding: 0 20px;
  text-align: center;

  > h2 {
    font-size: 1.8rem;
    font-weight: 900;
    word-break: keep-all;
    line-height: 2rem;
    margin-bottom: 20px;
  }

  > h3 {
    font-size: 1.2rem;
  }
`;

const HomeBtn = styled.button`
  background-color: #372874;
  border: 1px solid #372874;
  color: #fff;
  font-size: 20px;
  padding: 5px 25px;
  cursor: pointer;
  margin-top: 25px;
  transition: all 300ms ease;

  &:hover {
    background-color: #ffce1f;
    color: #444;
  }
`;

const FailPay = () => {
  return (
    <Layout>
      <ErrorContainer direction={"column"} justify="center" align="center">
        <ErrorPage>
          <h2>결제가 이루어지지 않았습니다!😢</h2>
          <h3>혹시 취소하셨거나 이미 결제했던 계정인가요?</h3>
          <h3>만약 아니라면 카드와 잔액을 확인해주세요!</h3>
        </ErrorPage>
        <Link to="/token">
          <HomeBtn>다시 가입하기</HomeBtn>
        </Link>
      </ErrorContainer>
    </Layout>
  );
};

export default FailPay;
