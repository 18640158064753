import React from "react";
import { Link } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import styled from "styled-components";
import { DoneButton, DoneContainer } from "Styles/ComponentStyled";
import Layout from "Common/Layout";

const DoneText = styled(Box)`
  text-align: center;
  word-break: keep-all;

  > h2 {
    font-size: 35px;
    font-weight: 900;
    margin-bottom: 15px;
  }

  > h3 {
    font-size: 18px;
    line-height: 30px;
  }

  @media screen and (max-width: 768px) {
    > h2 {
      font-size: 1.2rem;
    }
  }
`;

const PayDone = () => {
  return (
    <Layout>
      <DoneContainer justify="center" align="center" className="DoneContainer">
        <Flex direction="column" gap="30px" align="center">
          <DoneText>
            <h2>토큰 결제가 완료되었습니다!🥳</h2>
            <h3>결제 내역은 마이 페이지에서 확인하실 수 있습니다.</h3>
          </DoneText>
          <DoneButton
            direction={{ base: "column", md: "row" }}
            align={{ base: "auto", md: "center" }}
          >
            <button>
              <Link to="/mypage">마이 페이지</Link>
            </button>
            <button>
              <Link to="/">서비스 이용하기</Link>
            </button>
          </DoneButton>
        </Flex>
      </DoneContainer>
    </Layout>
  );
};

export default PayDone;
