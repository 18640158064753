//login 페이지

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Box, Flex, Heading, Text, useToast } from "@chakra-ui/react";
import styled from "styled-components";
import "Styles/Main.scss";
import Loading from "Shared/Loading";
import * as server from "Config/server";
import Layout from "Common/Layout";

const LoginTitle = styled(Flex)`
  > h3 {
    font-size: 30px;
    font-weight: 600;
    line-height: 35px;
  }

  > p {
    font-size: 15px;
    margin-bottom: 35px;
  }
`;

const LogBtn = styled.button`
  background-color: #b1b5e6;
  color: #444;
  padding: 10px 15px;
  outline: 0;
  border: 1px solid #b1b5e6;
  margin: 25px 0 15px 0;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  transition: all 300ms ease;

  &:hover {
    font-weight: 600;
    color: #fff;
    background-color: #ff9300;
    border: 1px solid #ff9300;
  }
`;

const Line = styled.div`
  height: 1px;
  width: 50%;
  border-bottom: 1px solid #aaa;
`;

const Admin = () => {
  const Toast = useToast();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [LoginInput, SetLoginInput] = useState({
    loginId: "",
    loginPassword: "",
  });
  const [token, setToken] = useState(0);
  const [userUid, setUserUid] = useState("");
  const [adminToken, setAdminToken] = useState("");

  const { loginId, loginPassword } = LoginInput;

  const LoginChange = (e) => {
    SetLoginInput({ ...LoginInput, [e.target.name]: e.target.value });
  };

  const onGoLogin = async (e) => {
    e.preventDefault();

    const isBlank = Object.values(LoginInput);

    if (isBlank.includes("") === true) {
      Toast({
        position: "top-right",
        title: "빈 칸이 남아있습니다.",
        description: "빈 칸을 채워주세요.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);

    const axiosValue = {
      method: "post",
      url: `${server.SERVER_URL}/admin/login`,
      data: {
        id: loginId,
        password: loginPassword,
      },
    };

    const response = await axios(axiosValue).catch((error) => {
      console.log(`error: ${error}`);
      setLoading(false);
    });
    if (response === undefined) return;
    setAdminToken(response.data.data.token);
    setLoading(false);
  };

  const onProvideToken = async (e) => {
    e.preventDefault();

    if (token < 0 || !userUid) {
      Toast({
        position: "top-right",
        title: "빈 칸이 남아있습니다.",
        description: "빈 칸을 채워주세요.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);

    const url = new URL(`${server.SERVER_URL}/admin/user/plan/drawingel`);
    url.searchParams.append("user_uid", userUid);

    const axiosValue = {
      method: "put",
      url: url.toString(),
      headers: {
        Authorization: "Bearer " + adminToken,
      },
      data: { token: parseInt(token) },
    };

    const response = await axios(axiosValue).catch((error) => {
      console.log(`error: ${error}`);
      setLoading(false);
    });
    if (response === undefined) return;
    if (response.status === 201) {
      Toast({
        position: "top-right",
        title: "success",
        description: `지급 성공!`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  return (
    <>
      {isLoading && <Loading />}
      <Layout>
        <Box p={{ base: "5em 2em", md: "8em 5em" }} bg="#f9f9f9">
          <Box maxW="350px" m="0 auto">
            <LoginTitle direction={"column"}>
              <Heading as="h3" size="md">
                로그인
              </Heading>
              <Text>로그인 후 드로잉젤 서비스를 즐겨보세요!</Text>
            </LoginTitle>
            <form>
              <Flex direction={"column"} gap="5px">
                <Box>
                  <label className="labelFlex">
                    아이디
                    <input
                      className="signInInput"
                      placeholder="example"
                      required
                      name="loginId"
                      onChange={LoginChange}
                    />
                  </label>
                </Box>
                <Box>
                  <label className="labelFlex">
                    비밀번호
                    <input
                      className="signInInput"
                      type="password"
                      placeholder="password"
                      required
                      name="loginPassword"
                      onChange={LoginChange}
                    />
                  </label>
                </Box>
              </Flex>

              <LogBtn type="submit" onClick={onGoLogin}>
                로그인
              </LogBtn>
            </form>
            <Box textAlign="center" m="35px 0">
              <Flex justify={"center"} align="center">
                <Line></Line>
                <Line></Line>
              </Flex>
            </Box>

            {adminToken && (
              <form>
                <Flex direction={"column"} gap="5px">
                  <Box>
                    <label className="labelFlex">
                      사용자 uid
                      <input
                        className="signInInput"
                        placeholder="example"
                        required
                        name="email"
                        onChange={(e) => setUserUid(e.target.value)}
                      />
                    </label>
                  </Box>
                  <Box>
                    <label className="labelFlex">
                      지급할 토큰
                      <input
                        className="signInInput"
                        type="number"
                        placeholder="1"
                        required
                        name="token"
                        onChange={(e) => setToken(e.target.value)}
                      />
                    </label>
                  </Box>
                </Flex>

                <LogBtn type="submit" onClick={onProvideToken}>
                  지급
                </LogBtn>
              </form>
            )}
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default Admin;
