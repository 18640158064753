import React, { useState } from "react";
import axios from "axios";

import { Box, Flex, Heading } from "@chakra-ui/react";
import styled from "styled-components";
import "Styles/Main.scss";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Toastify from "Common/Toastify";
import * as server from "Config/server";

const SaveList = styled(Flex)`
  padding: 28px 20px;
  border-bottom: 1px solid #444;
  gap: 15px;

  &:last-child {
    border-bottom: 0;
  }
`;

const DeleteBtn = styled.button`
  background-color: #ff635c;
  border: 1px solid #ff635c;
  color: #fff;
`;

const ListTitle = styled(Box)`
  padding: 18px 15px;
  background-color: #a98dec;
  color: #000;
  border-bottom: 1px solid $dark-1;
  display: flex;
  align-items: center;
  gap: 10px;

  > h3 {
    font-weight: 600;
  }

  > p {
    font-size: 15px;
  }

  @media screen and (max-width: 768px) {
    border-bottom: 0;
    flex-direction: column;
  }
`;

const BoxLeft = styled(Flex)`
  > button {
    width: 80px;
    padding: 2px 8px;
    cursor: pointer;
    font-size: 14px;

    transition: all 300ms ease;
  }

  > button:first-child {
    background-color: #372874;
    border: 1px solid #372874;
    color: #fff;

    &:hover {
      background-color: #4046b7;
      border: 1px solid #4046b7;
    }
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

const TingelSaveData = ({ savedata }) => {
  const userdata = JSON.parse(sessionStorage.getItem("UserData"));
  const [toast, setToast] = useState({
    category: "",
    title: "",
    description: "",
  });
  const [show, setShow] = useState(false);
  const { category, title, description } = toast;

  const onCopied = () => {
    if (!savedata) {
      setToast({
        ...toast,
        category: "info",
        title: "복사할 내용이 없습니다.",
        description: "버튼을 눌러 결과를 만들어주세요!",
      });
      setShow(true);
    }
    if (savedata) {
      setToast({
        ...toast,
        category: "success",
        title: "성공!",
        description: "내용을 복사했어요.",
      });
      setShow(true);
    }
  };

  const DeleteSave = async (uid) => {
    await axios({
      method: "delete",
      url: `${server.SERVER_URL}/user/prompt/save/${uid}`,
      headers: { Authorization: "Bearer " + userdata.token },
    }).catch(() => {});

    await window.location.reload();
  };

  return (
    <Box border="1px solid #444" w="100%" maxW="1000px" bg="#fff">
      <ListTitle>
        <h3>최근 저장된 콘텐츠</h3>
        <p>_최대 10개까지 저장됩니다.</p>
      </ListTitle>
      {savedata.map((item) => (
        <SaveList direction="column" key={item.uid}>
          <Flex
            direction={{ base: "column", sm: "row" }}
            align="center"
            justify="space-between"
            gap={{ base: "10px", sm: "0" }}
          >
            <Heading
              as="h3"
              size="sm"
              textAlign={{ base: "left", md: "center" }}
              wordBreak="keep-all"
              fontWeight={"600"}
            >
              {item.name}
            </Heading>
            <BoxLeft align="center" justify="center" gap={"5px"}>
              <CopyToClipboard
                text={item.content_kr + "\n" + item.content_en}
                onCopy={onCopied}
              >
                <button>복사</button>
              </CopyToClipboard>

              <DeleteBtn onClick={() => DeleteSave(item.uid)}>삭제</DeleteBtn>
            </BoxLeft>
          </Flex>

          <Box w="100%">
            {item.content_kr}
            <hr style={{ margin: "5px 0" }} />
            {item.content_en}
          </Box>
        </SaveList>
      ))}
      <Toastify
        category={category}
        title={title}
        description={description}
        open={show}
        close={() => setShow(false)}
      />
    </Box>
  );
};

export default TingelSaveData;
