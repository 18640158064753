//로그인 회원가입쪽 로딩

import React from "react";
import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import styled from "styled-components";

const LoadContent = styled(Box)`
position: fixed;
width: 100%;
height: 100%;
top: 0;
left: 0;
background-color : rgba(255,255,255,0.95);
z-index: 999;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`;

const Loading = () => {
  return (
      <LoadContent>
        <Flex align={"center"} direction='column' gap="15px">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="#000"
            size="xl"
          />
          <Text fontWeight="600" fontSize={'lg'}>Loading...</Text>
        </Flex>
      </LoadContent>
  );
};

export default Loading;
