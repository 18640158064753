import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Box,  Heading, Input, Text } from "@chakra-ui/react";
import Loading from "Shared/Loading";
import MembershipPay from "./MembershipPay";
import axios from "axios";
import * as server from "../../../Config/server";
import OnlyHeaderLayout from "Common/OnlyHeaderLayout";

const Card = styled(Box)`
  border: 2px solid #444;
  border-radius: 10px;
  background-color: #fff;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const Membership = () => {
  const userdata = JSON.parse(sessionStorage.getItem("UserData"));
  const [isLoading, SetLoading] = useState(false);
  const [plan, setPlan] = useState("");
  const [count, setCount] = useState(0);
  const [minCount, setMinCount] = useState(0);

  const onMembershipLoad = useCallback(async () => {
    if(userdata && userdata.token){
    const response = await axios({
      method: "get",
      url: `${server.SERVER_URL}/user/pay/drawingel/price`,
      headers: {
        Authorization: "Bearer " + userdata.token,
      },
    }).catch((error) => {
      console.log(`error: ${error}`);
    });
    setMinCount(response.data.data["drawingelCreditMinCount"]);
    setCount(response.data.data["drawingelCreditMinCount"]);
    setPlan(response.data.data["drawingelCreditPrice"]);
  }
  }, [userdata]);

  useEffect(() => {
    if(userdata) {
      onMembershipLoad();
    }
  }, [userdata, onMembershipLoad]);

  const onCountInputBlur = (e) => {
    setCount((prevState) => (prevState < 100 ? 100 : prevState));
  };

  return (
    <OnlyHeaderLayout>
      {isLoading && <Loading />}
      <Box p={{ base: "5em 2em", sm: "5em 5em 10em" }} bg='#ededed' minH='calc(100vh - 80px)'>
        <Heading maxW='200px' m='0 auto' as='h2' size='lg' mb='2em' textAlign={"center"} p='10px' border={'2px solid #444'} borderRadius='30px' bg='#fff'>
          멤버십
        </Heading>
        <Box maxW='768px' m='0 auto'>
          <Box mb='2em' lineHeight={'28px'}> 
            <Heading as='h3' size='md' mb='1em'>
              📍멤버십 결제 방법
            </Heading>
            <Text>1. 토큰 수량 선택(최소 80개부터 구매가능)</Text>
            <Text>2. 결제 방식 선택 후 결제하기 클릭</Text>
          </Box>

          <Card>
            <Box p='30px 20px'>
              <Text fontSize='xl' fontWeight='600'>토큰 가격: {plan ? plan : 125}원</Text>
              <Text fontSize='lg' mb='2em'>최소 수량 : {minCount ? minCount : 80}개</Text>
              수량
              <Input
                min={minCount}
                value={count}
                onChange={(e) => setCount(e.target.value)}
                onBlur={onCountInputBlur}
                type={"number"}
              />
            </Box>
          </Card>
        </Box>
        <MembershipPay
          selected={true}
          count={count}
          Price={plan * count}
          Plan={plan}
          SetLoading={SetLoading}
        />
      </Box>
    </OnlyHeaderLayout>
  );
};

export default Membership;
