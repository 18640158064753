import React from "react";
import { Close } from "grommet-icons";
import { Box, Flex } from "@chakra-ui/react";
import styled from "styled-components";

const Wrapper = styled(Box)`
  position: sticky;
  top: 30%;
  left: 50%;
`;

const Container = styled(Box)`
  max-width: 300px;
  min-width: 200px;

  margin: 0 auto;
  background-color: #fff;
`;

const ModalHeader = styled(Flex)`
  padding: 5px;
  font-weight: 600;
  color: #ffffff;
`;

const Button = styled.button`
  background-color: transparent;
  outline: none;
  cursor: pointer;
  border: 0;
`;

const ModalMain = styled(Box)`
  padding: 16px 20px;
  border-bottom: 1px solid #ffffff;
  border-top: 1px solid #ffffff;
  border-radius: 15px;

  > h3 {
    text-align: center;
    color: #000;
    padding-bottom: 25px;
  }
`;

const SmallModals = ({ open, close, children }) => {
  return (
    <>
      {open && (
        <Box className={open && "modals"}>
          <Wrapper>
            <Container>
              <ModalHeader align="center" justify="flex-end">
                <Button onClick={close}>
                  <Close size="15px" />
                </Button>
              </ModalHeader>
              <ModalMain>{children}</ModalMain>
            </Container>
          </Wrapper>
        </Box>
      )}
    </>
  );
};

export default SmallModals;
