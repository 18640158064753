import React, { lazy, Suspense, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "Components/Main/Home";
import Login from "Components/SignIn/Login";
import Signup from "Components/SignIn/Signup";
import TingelBox from "Components/Main/MyPage/TingelBox";

import Payment from "Common/Payment";
import Welcome from "Components/SignIn/Welcome";
import AuthPage from "Components/SignIn/AuthPage";

import PayRedirect from "Shared/PayRedirect";
import CardPayDone from "Shared/CardPayDone";
import PayDone from "Shared/PayDone";
import FailPay from "Shared/FailPay";

import ReactGA from "react-ga";
import NotFound from "Shared/404";
import EmailSignUp from "Components/SignIn/EmailSignUp";
import useScrollToTop from "Shared/useScrollTop";
import Membership from "Components/Main/Membership";
import Admin from "../Components/Main/Admin";
import Loading from "Shared/Loading";

const MyPage = lazy(() => import("Components/Main/MyPage"));
const Modify = lazy(() => import("Components/Main/MyPage/Modify"));

//Router
function App() {
  //const navigate = useNavigate();
  useScrollToTop();
  const location = useLocation();
  useEffect(() => {
    ReactGA.initialize("UA-212875619-1");
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page

    // ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        {/* main 헤더 */}
        <Route path="/" element={<Home />} />
        {/*<Route path="/edit" element={<Edit />} />*/}
        {/*<Route path="/transform" element={<Transform />} />*/}
        <Route path="/admin" element={<Admin />} />

        {/* main 헤더_멤버십 */}
        <Route path="/token" element={<Membership />} />
        <Route path="/pay_redirect" element={<PayRedirect />} />
        <Route path="/cardpay" element={<CardPayDone />} />
        <Route path="/paydone" element={<PayDone />} />
        <Route path="/fail" element={<FailPay />} />

        {/* 로그인, 회원가입*/}
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signup/email" element={<EmailSignUp />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/oauth" element={<AuthPage />} />

        {/* 마이페이지, 팅젤 보관함  */}
        <Route path="/mypage" element={<MyPage />} />
        <Route path="/modify" element={<Modify />} />
        <Route path="/tingelbox" element={<TingelBox />} />
        <Route path="/mypage/payment" element={<Payment />} />

        {/* 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

export default App;
