//메인 홈

import React, { useCallback, useEffect, useState } from "react";
import Layout from "Common/Layout";
import {
  Box, Flex, Grid, GridItem, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper,
  Textarea, useToast, Text, Button
} from "@chakra-ui/react";
import "@kfonts/neodgm";
import * as server from "../../Config/server";
import axios from "axios";
import Loading from "../../Shared/Loading";
import { useLocation } from "react-router-dom";

import { IoDocumentOutline } from 'react-icons/io5';
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useRecoilValue } from "recoil";
import { ProfileState } from "Config/recoil";
import { LabelText } from "Styles/ComponentStyled";

import { Select } from "@chakra-ui/react/dist/chakra-ui-react.cjs";


const Home = () => {
  const Toast = useToast();
  const location = useLocation();
  const profile = useRecoilValue(ProfileState);

  const [isLoading, setLoading] = useState(false);
  const [userdata, setUserData] = useState(
    JSON.parse(sessionStorage.getItem("UserData"))
  );
  const [buttonVisible, setButtonVisible] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [count, setCount] = useState(1);
  const [imgSrcList, setImgSrcList] = useState([]);
  const [selectedSizePresetIndex, setSelectedSizePresetIndex] = useState(0);

  const [selectedImageModel, setSelectedImageModel] = useState('dall-e-3')


  const sizePreset = [
    { width: 1024, height: 1024 },
    { width: 1024, height: 1792 },
    { width: 1792, height: 1024 },
  ];

  const sizePresetToString = (size) => {
    return `${size.width} x ${size.height}`;
  };

  const getQueryParam = (key) => {
    const queryParam = new URLSearchParams(location.search);
    return queryParam.get(key);
  };

  const onShowBtn = () => {
    if (window.scrollY > 40) {
      setButtonVisible(true);
    }
    if (window.scrollY < 40) {
      setButtonVisible(false);
    }
  };

  // This function will scroll the window to the top
  const onGoToTop = () => {
    window.scrollTo({
      top: "10px",
      behavior: "smooth", // for smoothly scrolling
    });
  };

  const onPromptChange = useCallback((event) => {
    setPrompt(event.target.value);
  }, []);

  const onSizeChange = useCallback((index) => {
    setSelectedSizePresetIndex(index);
  }, []);

  const onCountChange = useCallback((valueOfString, valueOfNumber) => {
    setCount(isNaN(valueOfNumber) ? 0 : valueOfNumber);
  }, []);

  const onCreateBtnClick = useCallback(async () => {
    if (!userdata) {
      Toast({
        position: "top-right",
        title: "로그인 필요",
        description: "로그인 해주세요!",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);
    const response = await axios({
      method: "post",
      url: `${server.SERVER_URL}/user/prompt/drawingel`,
      headers: {
        Authorization: "Bearer " + userdata.token,
      },
      data: {
        prompt,
        size: sizePresetToString(
          sizePreset[selectedSizePresetIndex]
        ).replaceAll(" ", ""),
        n: count,
        model: selectedImageModel
      },
    }).catch((error) => {
      console.log(`error: ${error}`);
      setLoading(false);
    });
    if (!response || !response?.data?.data?.data) {
      Toast({
        position: "top-right",
        title: "오류",
        description: "이미지 생성을 실패했습니다.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
      return;
    }

    console.log(response)

    if(selectedImageModel === 'dall-e-3'){
      setImgSrcList(response.data.data.data.map((data) => `data:image/png;base64,${data.b64_json}`));
    }else if(selectedImageModel === 'flux-pro'){
      setImgSrcList(response.data.data.data)
    }
 

    const storedPrompt = localStorage.getItem("prompt");
    if (storedPrompt) {
      localStorage.removeItem("prompt");
    }

    setLoading(false);
  }, [count, prompt, selectedSizePresetIndex, userdata,Toast,selectedImageModel,sizePreset]);


  useEffect(() => {
    window.addEventListener("scroll", onShowBtn);

    return () => {
      window.removeEventListener("scroll", onShowBtn);
    };
  }, []);

  useEffect(() => {
    const initPrompt = getQueryParam("prompt");
    if (initPrompt) {
      localStorage.setItem("prompt", initPrompt);
      setPrompt(initPrompt);
      return;
    }
    const prompt = localStorage.getItem("prompt");
    if (prompt) {
      setPrompt(prompt);
    }
  }, []);

  return (
    <Layout>
      {isLoading && <Loading />}
          <Flex minW={{base:"100%", lg:'500px'}} direction={"column"} p={{base:'3em', md:'10em 3em'}} gap="2em" >
            <Box>
              <LabelText>이미지 크기</LabelText>
              <Flex direction={{base:'column', md:'row'}} gap={"12px"} >
                {sizePreset.map((size, index) => (
                  <label className="checkStyle" key={index}>
                    <input
                      type="checkbox"
                      onChange={() => onSizeChange(index)}
                      checked={selectedSizePresetIndex === index}
                    />
                    {sizePresetToString(size)}
                  </label>
                ))}
              </Flex>
            </Box>

            <Box>
              <LabelText>이미지 내용</LabelText>
              <Text>📍 그리고 싶은 이미지 내용을 적어주세요!</Text>
              <Textarea
                backgroundColor={"#ffffff"}
                required
                name="LogEmail"
                value={prompt}
                onChange={onPromptChange}
                minHeight="300px"
                placeholder="(예: 크비즘 스타일로 그린 카페에 가는 직장인)"
                mt='10px'
              ></Textarea>
            </Box>

            <Box>

              <LabelText>이미지 생성 모델</LabelText>
              <Select value={selectedImageModel} onChange={(e) => {setSelectedImageModel(e.target.value)}}>
                <option value="dall-e-3">dall-e-3</option>
                <option valuy={'flux-pro'}>flux-pro</option>
              </Select>
            </Box>

            <Box>

              <LabelText>이미지 개수</LabelText>
              <NumberInput
                step={1}
                min={0}
                max={10}
                value={count}
                onChange={onCountChange}
                backgroundColor={"#FFFFFF"}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Box>

            <Button onClick={onCreateBtnClick} colorScheme='purple'>
              그림 그리기
              {profile.user.draw_count && (`(잔여 토큰 : ${profile.user.draw_count})`)}
              </Button>

          </Flex>
          <Box p={{base:'3rem', lg:'5rem'}} w='100%' borderLeft='1px solid #ededed'>
          {imgSrcList.length !== 0 ? (
            <>
               <Flex
               w='100%'
               align={"center"}
               direction={{ base: "column", sm: "row" }}
               gap='10px'
               mb='30px'
             >
               <CheckCircleIcon w={6} h={6} color={"#63d959"} />
               <Text
                 fontSize={"xl"}
                 fontWeight={600}
                 textAlign={{ base: "center", sm: "left" }}
               >
                 그림이 생성되었습니다!
               </Text>
             </Flex>
            <Grid
              templateColumns={{base:"repeat(1, 1fr)", lg:"repeat(2, 1fr)", xl:"repeat(3, 1fr)"}}
              backgroundColor={"#ffffff"}
              width="100%"
            >
              {imgSrcList.map((imgSrc) => (
                <GridItem key={imgSrc} backgroundColor={"#ffffff"} margin={"10px"}>
                  <img src={`${imgSrc}`} alt="드로잉젤_그림" />
                </GridItem>
              ))}
            </Grid>
            </>
          ) : (
              <Box w='100%' h='100%' m='0 auto'>
                <Flex
                  direction={'column'}
                  align='center'
                  justify={'center'}
                  p='20px'
                  color='#444'
                  resize='none'
                  h={"100%"}
                  minH='500px'
                  gap='10px'
                  border='1px solid #dbdbdb'
                >
                  <IoDocumentOutline size={30} />
                  <Text textAlign={'center'}>
                    아직 이미지가 <br /> 만들어지지 않았어요!
                  </Text>
                </Flex>
              </Box>
          )}
              </Box>
    </Layout>
  );
};

export default Home;
