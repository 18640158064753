import React, { useCallback, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  ConfirmBtn,
  ConfirmDiv,
  CreditCardButton,
  ElementBox,
  KakaoPayContainer,
  PayCheck,
  PayInfo,
  PhoneBox,
  SignMethodBox,
  WarningInfo,
} from "Styles/MembershipStyled";
import { CreditCard } from "grommet-icons";
import { Box, useToast } from "@chakra-ui/react";
import * as server from "Config/server";
import dayjs from "dayjs";
import Modal from "Common/SmallModals";
import { useRecoilValue } from "recoil";
import { ProfileUser } from "Config/recoil";
import Toastify from "Common/Toastify";

const MembershipPay = ({
  count,
  Price,
  Plan,
  SetLoading,
}) => {
  const { IMP } = window;
  const navigate = useNavigate();
  const Toast = useToast();
  const RecoilUserData = useRecoilValue(ProfileUser);
  const userdata = JSON.parse(sessionStorage.getItem("UserData"));

  const [isOpen, SetOpen] = useState(false);
  const [payMethod, setPayMethod] = useState("");
  const [buyer, setBuyer] = useState({
    buyerName: "",
    buyerEmail: "",
  });

  const [buyerTel, setBuyerTel] = useState({
    Tel1: "",
    Tel2: "",
    Tel3: "",
  });

  const [toast, setToast] = useState({
    category: "",
    title: "",
    description: "",
  });
  const [show, setShow] = useState(false);
  const [payComment, setPayComment] = useState("결제하기");

  const { category, title, description } = toast;

  const { buyerName, buyerEmail } = buyer;
  const { Tel1, Tel2, Tel3 } = buyerTel;

  const onCloseModal = () => {
    SetOpen(!isOpen);
  };

  const onChangeName = (e) => {
    setBuyer({ ...buyer, [e.target.name]: e.target.value });
  };

  const onChangeTel = (e) => {
    setBuyerTel({ ...buyerTel, [e.target.name]: e.target.value });
  };

  const onPayBtnClick = useCallback((type) => {
    setPayMethod(type);
  }, []);

  //카드결제
  const onPayWithCard = () => {
    if (userdata === null) {
      setToast({
        ...toast,
        category: "info",
        title: "로그인 후 사용 가능합니다.",
        description: "로그인을 먼저 해주세요!",
      });
      setShow(true);
    }

    const BuyerValue = Object.values(buyer);
    const BuyerTelValue = Object.values(buyerTel);
    const IsBuyerBlank = BuyerValue.includes("");
    const IsBuyerTelBlank = BuyerTelValue.includes("");

    if (IsBuyerBlank || IsBuyerTelBlank) {
      setToast({
        ...toast,
        category: "error",
        title: "빈 칸이 남아있습니다.",
        description: "빈 칸을 먼저 채워주세요",
      });
      setShow(true);
      return;
    }

    setPayComment("결제창을 불러오고 있습니다.");
    const now = new Date();
    const moidNum = dayjs(now).unix();
    const uid = RecoilUserData.user_uid;

    IMP.init("imp33624147");
    IMP.request_pay(
      {
        pg: "html5_inicis",
        pay_method: "card",
        merchant_uid: `${uid}_${Plan}_${moidNum}`, // 상점에서 관리하는 주문 번호를 전달
        name: `드로잉젤 ${Plan}개월`,
        amount: Price,
        buyer_email: buyerEmail,
        buyer_name: buyerName,
        buyer_tel: `${Tel1}-${Tel2}-${Tel3}`,
        m_redirect_url: "https://drawingel.com/cardpay", //모바일 전용 -> cardPayDone으로 이동
      },
      async (response) => {
        if (!response.success) {
          setPayComment("결제하기");
          SetLoading(false);
          navigate("/fail");
          Toast({
            position: "top-right",
            title: "error",
            description: ` ${response.error_msg}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }

        SetLoading(true);

        await axios({
          method: "post",
          url: `${server.SERVER_URL}/user/pay/drawingel`,
          headers: { Authorization: "Bearer " + userdata.token },
          data: {
            service: "inicis",
            imp_uid: response.imp_uid,
            merchant_uid: response.merchant_uid,
            plan: parseInt(count),
          },
        }).catch((error) => {
          setPayComment("결제하기");

          if (error.response.status === 403) {
            SetLoading(false);
            Toast({
              position: "top-right",
              title: "error",
              description: ` ${error.response.data.message}`,
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
          if (error.response.status === 500) {
            SetLoading(false);

            Toast({
              position: "top-right",
              title: "error",
              description: ` ${error.response.data.message}`,
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
        });

        setPayComment("결제하기");
        SetLoading(false);
        navigate("/paydone");
        setTimeout(
          Toast({
            position: "top-right",
            title: "success",
            description: `결제 성공!`,
            status: "success",
            duration: 3000,
            isClosable: true,
          }),
          5000
        );
      }
    );
  };

  //카카오페이
  const onPayWithKakao = () => {
    if (userdata === null) {
      setToast({
        ...toast,
        category: "info",
        title: "로그인 후 사용 가능합니다.",
        description: "로그인을 먼저 해주세요!",
      });
      setShow(true);
      return;
    }

    SetLoading(true);

    const now = new Date();
    const moidNum = dayjs(now).unix();
    const uid = RecoilUserData.user_uid;

    IMP.init("imp33624147");
    IMP.request_pay(
      {
        pg: "kakaopay",
        merchant_uid: `${uid}_${Plan}_${moidNum}`, // 상점에서 관리하는 주문 번호
        name: `드로잉젤 토큰 ${count} 개`,
        amount: Price,
        // customer_uid: `customer_${uid}`,
        buyer_email: userdata.email,
        buyer_name: userdata.userName,
        m_redirect_url: "https://drawingel.com/pay_redirect",
      },
      async (response) => {
        if (!response.success) {
          SetLoading(false);
          navigate("/fail");
          Toast({
            position: "top-right",
            title: "error",
            description: ` ${response.error_msg}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }

        SetLoading(true);

        await axios({
          method: "post",
          url: `${server.SERVER_URL}/user/pay/drawingel`,
          headers: { Authorization: "Bearer " + userdata.token },
          data: {
            service: "kakao",
            imp_uid: response.imp_uid,
            merchant_uid: response.merchant_uid,
            plan: parseInt(count),
          },
        }).catch((error) => {
          if (error.response.status === 403) {
            SetLoading(false);

            Toast({
              position: "top-right",
              title: "error",
              description: ` ${error.response.data.message}`,
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
          if (error.response.status === 500) {
            SetLoading(false);

            Toast({
              position: "top-right",
              title: "error",
              description: ` ${error.response.data.message}`,
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
        });

        SetLoading(false);
        navigate("/paydone");
        setTimeout(
          Toast({
            position: "top-right",
            title: "success",
            description: `결제 성공!`,
            status: "success",
            duration: 3000,
            isClosable: true,
          }),
          5000
        );
      }
    );
  };

  return (
    <>
      <SignMethodBox justify="center" align="center">
        <button
          align="center"
          className="kakaoPayBtn"
          onClick={() => onPayBtnClick("kakao")}
        >
          <img src="kakao_pay.png" alt="카카오페이" />
          <span>카카오페이</span>
        </button>
        <button
          align="center"
          className="creditNormal"
          onClick={() => onPayBtnClick("card")}
        >
          <CreditCard />
          <span>카드결제</span>
        </button>
      </SignMethodBox>
      {payMethod === "kakao" && (
        <KakaoPayContainer>
          <div>
            <PayInfo align="center" justify="space-between">
              <h4>토큰 </h4>
              <p>{count ? `${count}개` : "로그인 후 이용해주세요."}</p>
            </PayInfo>
            <PayInfo align="center" justify="space-between">
              <h4>결제 </h4>
              <p>{Price ? Price : "로그인 후 이용해주세요."}</p>
            </PayInfo>
          </div>
          <button onClick={onPayWithKakao}>결제하기</button>
        </KakaoPayContainer>
      )}

      {payMethod === "card" && (
        <>
          <Box
            w="100%"
            maxW={"500px"}
            m="0 auto"
            bg="#fff"
            border="1px solid #372874"
            borderRadius="10px"
            p={{ base: "30px 18px", md: "25px" }}
          >
            <WarningInfo>
              <p>
                * 메일주소와 번호를 <span>정확하게</span> 적어주세요.
              </p>
              <p>
                * <span>적어주신 메일과 번호로</span> 결제가 진행됩니다.
              </p>
              <p>
                * <span>결제창이 열린 후</span>, 카드 앞면에 사용자 실명이
                기재된 기명 법인카드의 경우 생년월일을, 카드 앞면에 회사 명칭이
                기재된 무기명 법인 카드일 경우 사업자 번호를 입력해주세요.
              </p>
              <p></p>
            </WarningInfo>
            <ElementBox>
              <p>이름</p>
              <input
                value={buyerName || ""}
                onChange={onChangeName}
                name="buyerName"
              ></input>
            </ElementBox>

            <ElementBox>
              <p>E-mail</p>
              <input
                value={buyerEmail || ""}
                onChange={onChangeName}
                name="buyerEmail"
              ></input>
            </ElementBox>

            <PhoneBox>
              <p>휴대폰/전화번호</p>
              <div>
                <input value={Tel1 || ""} onChange={onChangeTel} name="Tel1" />
                <span>-</span>
                <input value={Tel2 || ""} onChange={onChangeTel} name="Tel2" />
                <span>-</span>
                <input value={Tel3 || ""} onChange={onChangeTel} name="Tel3" />
              </div>
            </PhoneBox>
          </Box>
          <PayCheck>
            <PayInfo align="center" justify="space-between">
              <h4>토큰 </h4>
              <p>{count ? `${count}개` : "로그인 후 이용해주세요."}</p>
            </PayInfo>
            <PayInfo align="center" justify="space-between">
              <h4>결제 </h4>
              <p>{Price? `${Price}` : "로그인 후 이용해주세요."}</p>
            </PayInfo>
            <CreditCardButton onClick={onPayWithCard}>
              {payComment}
            </CreditCardButton>
          </PayCheck>
        </>
      )}
      <Modal open={isOpen} close={onCloseModal}>
        <ConfirmDiv direction="column" align="center" justify="center">
          <h3>원하시는 멤버십을 선택해주세요!</h3>
          <ConfirmBtn onClick={onCloseModal}>확인</ConfirmBtn>
        </ConfirmDiv>
      </Modal>
      <Toastify
        category={category}
        title={title}
        description={description}
        open={show}
        close={() => setShow(false)}
      />
    </>
  );
};

export default MembershipPay;
