import React, { useState } from "react";
import {
  Avatar,
  Box,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useMediaQuery,
  HStack,
  useToast
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import Drawer from "./Drawer";
import { LogBtn } from "Styles/ComponentStyled";

const Header = ({ userdata }) => {
  const Toast = useToast();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [isOpen, setOpen] = useState(false);

  const onHandleMenu = () => {
    setOpen(!isOpen);
  };

  return (
    <Flex
      align="center"
      justify={"space-between"}
      p="15px 20px"
      backgroundColor={"#ededed"}
      color={"#000"}
      borderBottom={"1px solid #000"}
      position="sticky"
      top="0"
      zIndex={"10"}
    >
      <Link to="/">
        <Text bg="#fff" fontSize="2xl" fontWeight="600" className="NeoFont" p="5px 20px" border="2px solid #000"
              borderRadius={"30px"}>드로잉젤</Text>
      </Link>
      {isLargerThan768 ? (
        <Flex gap="50px">
          <HStack spacing={"40px"}>
            <Menu>
              <MenuButton _hover={{ fontWeight: 600, transition: "all 200ms ease-in-out" }} fontSize="xl">🎨 AI
                서비스</MenuButton>
              <MenuList>
                <MenuItem as="a" href="/">생성 Draw</MenuItem>
                {/*<MenuItem as='a' href='/transform'>변형 Transform</MenuItem>*/}
                {/*<MenuItem as='a' href='/edit'>편집 Generator</MenuItem>*/}
                <MenuItem as="a">
                  <button onClick={() => Toast({
                    position: "top-right",
                    title: "안내",
                    description: "준비중입니다.",
                    status: "info",
                    duration: 3000,
                    isClosable: true
                  })}>
                    변형 Transform
                  </button>
                </MenuItem>
                <MenuItem as="a">
                  <button onClick={() => Toast({
                    position: "top-right",
                    title: "안내",
                    description: "준비중입니다.",
                    status: "info",
                    duration: 3000,
                    isClosable: true
                  })}>
                    편집 Generator
                  </button>
                </MenuItem>
              </MenuList>
            </Menu>
            <Menu>
              <Link to="/token">
                <MenuButton _hover={{ fontWeight: 600, transition: "all 200ms ease-in-out" }} fontSize="xl">💳
                  결제</MenuButton>
              </Link>
            </Menu>
          </HStack>
          {userdata && userdata.isLogin ? (
            <Link to="/mypage">
              <Avatar
                bg="#fff"
                border="1px solid #444"
                name="username"
                src={userdata.userImage ? userdata.userImage : "/user.png"}
              />
            </Link>
          ) : (
            <Link to="/login">
              <LogBtn>로그인</LogBtn>
            </Link>
          )}
        </Flex>
      ) : (
        <Box>
          <HamburgerIcon w={6} h={6} color="#000" onClick={onHandleMenu} />
          {isOpen && <Drawer userdata={userdata} onClose={onHandleMenu} />}
        </Box>
      )}
    </Flex>
  );
};

export default Header;
