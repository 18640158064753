//카카오 로그인용 Authpage

import React, { useCallback, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loading from "Shared/Loading";
import * as server from "Config/server";
import { useToast } from "@chakra-ui/react";

const AuthPage = () => {
  const { Kakao } = window;
  const navigate = useNavigate();
  const Toast = useToast();

  const kakaoRequest = (access) => {
    Kakao.API.request({
      url: "/v2/user/me",
      success: async (kakaoResponse) => {
        let email = kakaoResponse.kakao_account.email;
        let profile = kakaoResponse.kakao_account.profile;

        let nickname = kakaoResponse.properties.nickname;
        let photoURL = profile.thumbnail_image_url;

        const response = await axios({
          method: "post",
          url: `${server.SERVER_URL}/user/login`,
          data: {
            provider: "kakao",
            token: access,
          },
        }).catch(() => {
          navigate("/login", { replace: true });
          setTimeout(
            Toast({
              position: "top-right",
              title: "Fail",
              description: "유저 정보를 가져올 수 없습니다!",
              status: "error",
              duration: 3000,
              isClosable: true,
            }),
            3000
          );
        });

        const userData = {
          isLogin: true,
          token: response.data.data.token,
          email: email,
          create: response.connected_at,
          provider: "kakao",
          displayName: nickname,
          userName: nickname,
          userImage: photoURL,
        };
        sessionStorage.setItem("UserData", JSON.stringify(userData));
        setTimeout(navigate("/", { replace: true }), 3000);
      },
      fail: (error) => {
        navigate("/signup", { replace: true });
        setTimeout(
          Toast({
            position: "top-right",
            title: "Fail",
            description: "유저 정보를 가져올 수 없습니다!",
            status: "error",
            duration: 3000,
            isClosable: true,
          }),
          3000
        );
      },
    });
  };

  const onInit = useCallback(async () => {
    const search = new URL(window.location.href);
    const code = search.searchParams.get("code");
    const error = search.searchParams.get("error");
    const grantType = "authorization_code";
    const clientId = "cc67916adadf130f20e79f6d4a622909";
    const redirectUri = "https://drawingel.com/oauth";
    //const redirectUri = "http://localhost:3000/oauth";

    if (error) {
      Toast({
        position: "top-right",
        title: "Fail",
        description: "No AuthorizeCodeFromKakao",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    if (!code) {
      console.log("No AuthorizeCodeFromKakao");
      return;
    }

    const res = await axios({
      method: "post",
      url: `https://kauth.kakao.com/oauth/token?grant_type=${grantType}&client_id=${clientId}&redirect_uri=${redirectUri}&code=${code}`,
      headers: {
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    });
    const access = res.data.access_token;
    // sessionStorage.setItem("token", access);
    await Kakao.Auth.setAccessToken(access);

    const signupRes = await axios({
      method: "post",
      url: `${server.SERVER_URL}/user/signup`,
      data: {
        provider: "kakao",
        token: access,
      },
    }).catch((error) => {
      if (error.response.status === 403) {
        kakaoRequest(access);
      }
    });

    if (signupRes?.status === 201) {
      kakaoRequest(access);
    }
  }, []);

  useEffect(() => {
    onInit();
  }, []);

  return <Loading />;
};

export default AuthPage;
