import React, { useEffect } from "react";
import axios from "axios";
import {  useToast } from "@chakra-ui/react";
import "Styles/common.scss";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { ProfileState } from "Config/recoil";
import { authService } from "Config/FirebaseConfig";
import * as server from "Config/server";
import Header from "./Header";

const OnlyHeaderLayout = ({ children }) => {
  const { Kakao } = window;
  const Toast = useToast();
  const profile = useRecoilValue(ProfileState);
  const setProfile = useSetRecoilState(ProfileState);

  const userdata = JSON.parse(sessionStorage.getItem("UserData"));

  const onSignOut = async () => {
    if (userdata.provider === "kakao") {
      Kakao.Auth.logout(() => {
        sessionStorage.clear();
        window.location.reload();
      });
    } else {
      sessionStorage.clear();
      await authService.signOut();
      window.location.reload();
    }
  };

  const getProfile = async () => {
    const response = await axios({
      method: "get",
      url: `${server.SERVER_URL}/user/profile`,
      headers: {
        Authorization: "Bearer " + userdata.token,
      },
    }).catch((error) => {
      if (error.response.status === 412) {
        onSignOut();
        Toast({
          position: "top-right",
          title: "로그인이 만료되었습니다!",
          description: "다시 로그인 해주세요!",
          status: "info",
          duration: 3000,
          isClosable: true,
        });
      }
    });

    setProfile({
      ...profile,
      user: response.data.data.user,
      membership: response.data.data.membership,
    });
  };

  useEffect(() => {
    if (userdata && userdata.isLogin) {
      getProfile();
    }
  }, []);

  return (
    <>
      <Header userdata={userdata} />
      {children}
    </>
  );
};

export default OnlyHeaderLayout;
