//login 페이지

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Box, Flex, Heading, Text, useToast } from "@chakra-ui/react";
import styled from "styled-components";
import { FacebookOption, Google } from "grommet-icons";
import "Styles/Main.scss";
import Loading from "Shared/Loading";

import {
  browserSessionPersistence,
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { authService, firebaseInstance } from "Config/FirebaseConfig";
import * as server from "Config/server";
import dayjs from "dayjs";
import OnlyHeaderLayout from "Common/OnlyHeaderLayout";

const LogBtn = styled.button`
  background-color: #444;
  color: #fff;
  padding: 10px 15px;
  outline: 0;
  border: 2px solid #444;
  border-radius: 10px;
  margin: 25px 0 15px 0;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  transition: all 300ms ease;

  &:hover {
    font-weight: 600;
    background-color: #000;
    border: 2px solid #000;
  }
`;

const Line = styled.div`
  height: 1px;
  width: 50%;
  border-bottom: 1px solid #aaa;
`;


const Login = () => {
  const { Kakao } = window;
  const Toast = useToast();
  const navigate = useNavigate();

  const [isInApp, SetInApp] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [LoginInput, SetLoginInput] = useState({
    LogEmail: "",
    LogPassword: "",
  });

  const { LogEmail, LogPassword } = LoginInput;

  const LoginChange = (e) => {
    SetLoginInput({ ...LoginInput, [e.target.name]: e.target.value });
  };

  const LoginKakao = () => {
    Kakao.Auth.authorize({
      redirectUri: "https://drawingel.com/oauth",
      //redirectUri: "http://localhost:3000/oauth",
    });
  };

  const onGoLogin = async (e) => {
    e.preventDefault();

    const auth = getAuth();
    const isBlank = Object.values(LoginInput);

    if (isBlank.includes("") === true) {
      Toast({
        position: "top-right",
        title: "빈 칸이 남아있습니다.",
        description: "빈 칸을 채워주세요.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);
    await setPersistence(auth, browserSessionPersistence).catch(() => {
      setLoading(false);
    });

    const userCredential = await signInWithEmailAndPassword(
      auth,
      LogEmail,
      LogPassword
    ).catch((error) => {
      const errorMessage = error.message;
      const userNotFound = errorMessage.indexOf("user-not-found");
      const invalidEmail = errorMessage.indexOf("invalid-email");
      const wrongPassword = errorMessage.indexOf("wrong-password");

      if (userNotFound !== -1) {
        Toast({
          position: "top-right",
          title: "Fail",
          description: "삭제 되었거나 등록되지 않은 유저입니다.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
      }

      if (invalidEmail !== -1) {
        Toast({
          position: "top-right",
          title: "Fail",
          description: "올바른 이메일 형식이 아닙니다.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
      }

      if (wrongPassword !== -1) {
        Toast({
          position: "top-right",
          title: "비밀번호가 맞지 않습니다. ",
          description: "혹시 구글이나 페이스북 계정으로 가입한 계정일까요?",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
      }
    });

    const user = userCredential.user;
    const creationTime = user.metadata.creationTime;
    const formatCreation = dayjs(creationTime).format("YYYY-MM-DD");
    const startVerifyDate = dayjs("09 Feb 2022").format("YYYY-MM-DD");

    if (startVerifyDate <= formatCreation && !user.emailVerified) {
      Toast({
        position: "top-right",
        title: "Fail",
        description: "아직 메일이 인증 되지 않았습니다.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
    } else {
      const splitEmail = user.email.split("@");
      let providerId = user.providerData[0].providerId;
      let create = user.metadata.creationTime;
      let displayName = user.displayName;
      const token = user.accessToken;
      const userName = splitEmail[0];

      const response = await axios({
        method: "post",
        url: `${server.SERVER_URL}/user/login`,
        data: {
          provider: "firebase",
          token: token,
        },
      }).catch(async (error) => {
        if (error.response.status === 403) {
          sessionStorage.clear();
          Toast({
            position: "top-right",
            title: "Fail",
            description:
              "존재하지 않은 유저입니다. 회원가입을 먼저 진행해주세요!",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          setLoading(false);
        }
      });

      const userData = {
        isLogin: true,
        token: response.data.data.token,
        email: user.email,
        create: create,
        provider: providerId,
        displayName: displayName,
        userName: userName,
      };
      sessionStorage.setItem("UserData", JSON.stringify(userData));
      setLoading(false);
      //await getProfile(token);
      setTimeout(() => navigate("/", { replace: true }), 3000);
    }
  };

  // 구글 로그인
  const onLoginWithGoogle = async () => {
    setLoading(true);
    let provider = new firebaseInstance.auth.GoogleAuthProvider();
    await setPersistence(authService, browserSessionPersistence).catch(() => {
      setLoading(false);
    });

    const dataGoogle = await authService
      .signInWithPopup(provider)
      .catch((error) => {
        setLoading(false);
        if (error.code === "auth/account-exists-with-different-credential") {
          Toast({
            position: "top-right",
            title: "이미 페이스북으로 로그인했던 계정입니다. ",
            description:
              "동일한 이메일 주소를 사용하여 여러 계정을 만들 수 없습니다.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          setLoading(false);
        }
      });

    let credential = dataGoogle.credential;
    let user = dataGoogle.user;
    let providerId = credential.providerId;
    let email = user.email;
    let create = user.metadata.creationTime;
    let username = user.displayName;
    let userPhoto = user.photoURL;

    await TokenLogin(providerId, email, create, username, userPhoto);
  };

  // 페이스북
  const onLoginWithFacebook = async () => {
    setLoading(true);

    let provider = new firebaseInstance.auth.FacebookAuthProvider();

    await setPersistence(authService, browserSessionPersistence).catch(
      (error) => {
        setLoading(false);
      }
    );

    const dataFacebook = await authService
      .signInWithPopup(provider)
      .catch((error) => {
        setLoading(false);
        if (error.code === "auth/account-exists-with-different-credential") {
          Toast({
            position: "top-right",
            title: "이미 구글로 로그인했던 계정입니다. ",
            description:
              "동일한 이메일 주소를 사용하여 여러 계정을 만들 수 없습니다.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          setLoading(false);
        }
      });

    let user = dataFacebook.user;
    let providerId = dataFacebook.credential.providerId; //facebook.com
    let email = user.email;
    let create = user.metadata.creationTime;
    let username = user.displayName;
    let userPhoto = user.photoURL;

    await TokenLogin(providerId, email, create, username, userPhoto);
  };

  //getIdToken -> login
  const TokenLogin = async (providerId, email, create, username, userPhoto) => {
    const token = await authService.currentUser.getIdToken();

    const response = await axios({
      method: "post",
      url: `${server.SERVER_URL}/user/login`,
      data: {
        provider: "firebase",
        token: token,
      },
    }).catch(async (error) => {
      if (error.response.status === 403) {
        sessionStorage.clear();
        Toast({
          position: "top-right",
          title: "존재하지 않은 유저입니다. ",
          description: "회원가입을 먼저 진행해주세요!",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
        // setTimeout(navigate("/signup"), 3000);
      }
    });
    const userData = {
      isLogin: true,
      token: response.data.data.token,
      email: email,
      create: create,
      provider: providerId,
      userName: username,
      userImage: userPhoto,
    };
    sessionStorage.setItem("UserData", JSON.stringify(userData));
    setLoading(false);

    setTimeout(() => navigate("/", { replace: true }), 3000);
  };

  useEffect(() => {
    let userAgent = navigator.userAgent;
    let check = userAgent.indexOf("KAKAOTALK");
    let checkNaver = userAgent.indexOf("NAVER");
    let checkInsta = userAgent.indexOf("Instagram");
    let checkFB = userAgent.indexOf("FB");

    if (
      check !== -1 ||
      checkNaver !== -1 ||
      checkInsta !== -1 ||
      checkFB !== -1
    ) {
      SetInApp(true);
    } else {
    }
  }, [isInApp]);

  return (
    <>
      {isLoading && <Loading />}
      <OnlyHeaderLayout>
        <Box p={{ base: "5em 2em", md: "8em 5em" }} bg="#ededed" minH='calc(100vh - 80px)'>
          <Box maxW="350px" m="0 auto">
            <Flex direction={"column"}>
              <Heading as="h3" size="lg" mb='5px' fontWeight={600}>
                로그인
              </Heading>
              <Text fontSize={'md'} mb='35px'>로그인 후 드로잉젤 서비스를 즐겨보세요!</Text>
            </Flex>
            <form>
              <Flex direction={"column"} gap="5px">
                <Box>
                  <label className="labelFlex">
                    이메일
                    <input
                      className="signInInput"
                      placeholder="example@naver.com"
                      required
                      name="LogEmail"
                      onChange={LoginChange}
                    />
                  </label>
                </Box>
                <Box>
                  <label className="labelFlex">
                    비밀번호
                    <input
                      className="signInInput"
                      type="password"
                      placeholder="password"
                      required
                      name="LogPassword"
                      onChange={LoginChange}
                    />
                  </label>
                </Box>
              </Flex>

              <LogBtn type="submit" onClick={onGoLogin}>
                로그인
              </LogBtn>
            </form>
            {/*<RegL입ink>*/}
            {/*  아직 회원이 아니신가요? <Link to="/signup">회원가입</Link>*/}
            {/*</RegL입ink>*/}
            <Box textAlign="center" m="35px 0">
              <Flex justify={"center"} align="center">
                <Line></Line>
                <p className="orLink">or</p>
                <Line></Line>
              </Flex>
            </Box>
            <Box textAlign="center" mb="10px">
              <Flex direction={"column"} align="center" justify={"center"}>
                {!isInApp && (
                  <button
                    className="googleButton"
                    name="Google"
                    onClick={onLoginWithGoogle}
                  >
                    <Google color="plain" /> 구글 로그인
                  </button>
                )}
                <button
                  id="kakao-login-btn"
                  className="kakaoButton"
                  name="kakao"
                  onClick={LoginKakao}
                >
                  <img src="/kakao_symbol.png" alt="kakao" />
                  <span>카카오 로그인</span>
                </button>
                <button
                  className="facebookButton"
                  name="Facebook"
                  onClick={onLoginWithFacebook}
                >
                  <FacebookOption color="plain" /> 페이스북 로그인
                </button>
              </Flex>

              <div className="isChecked">
                <p>
                  <a
                    href="https://appplatform.notion.site/8be8232fff0341799cf8c13728610b6b"
                    target="_blank"
                    rel="noreferrer"
                  >
                    이용약관
                  </a>
                  과&nbsp;
                  <a
                    href="https://www.notion.so/appplatform/d99f247a66d141bbbdf227739861a0a2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    개인정보처리방침
                  </a>
                  을 확인하였고&nbsp;동의합니다.
                </p>
              </div>
            </Box>
          </Box>
        </Box>
      </OnlyHeaderLayout>
    </>
  );
};

export default Login;
