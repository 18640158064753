//결국 만든 멤버십 스타일들

import styled from "styled-components";
import { Box, Flex } from "@chakra-ui/react";

export const ConfirmDiv = styled(Flex)`
  gap: 25px;

  > h3 {
    font-size: 1rem;
  }
`;

export const ConfirmBtn = styled.button`
  background-color: #ffce1f;
  border: 1px solid #ffce1f;
  padding: 2px 10px;
  font-size: 1rem;
  width: 150px;
  cursor: pointer;
  color: #444;
`;

export const SignMethodBox = styled(Flex)`
  transition: all 300ms ease-in-out;
  width: 100%;
  padding: 48px 0;
  gap: 20px;
`;

export const KakaoPayContainer = styled(Box)`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  > button {
    width: 100%;
    background-color: #30009c;
    font-size: 1rem;
    color: #ffffff;
    border-radius: 8px;
    padding: 10px 30px;
    cursor: pointer;
    border: 0;
    outline: 0;
    margin-top: 15px;
  }
`;

export const ElementBox = styled(Box)`
  border-color: #ededed;
  padding-bottom: 10px;

  > p {
    font-size: 0.95em;
    margin-bottom: 0.4em;
    font-weight: 600;
  }

  > input {
    height: 40px;
    outline: 0;
    border: 1px solid #ededed;
    padding: 6px;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
  }
`;

export const PhoneBox = styled(Box)`
  > p {
    font-size: 0.95em;
    margin-bottom: 0.4em;
    font-weight: 600;
  }

  > div {
    display: flex;
    gap: 10px;

    > input {
      height: 40px;
      outline: 0;
      border: 1px solid #ededed;
      padding: 5px;
      border-radius: 5px;
      font-size: 16px;
      width: 80px;
    }

    @media screen and (max-width: 480px) {
      gap: 7px;
      > input {
        width: 60px;
      }
    }
  }
`;

export const PayCheck = styled(Box)`
  padding-top: 15px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  > button {
    width: 100%;
  }
`;

export const CreditCardButton = styled(Box)`
  background-color: #30009c;
  font-size: 1rem;
  color: #ffffff;
  border-radius: 8px;
  padding: 10px 30px;
  text-align: center;
  cursor: pointer;
  border: 0;
  outline: 0;
  margin-top: 15px;
  transition: all 300ms ease;

  &:hover {
    background-color: #517fe6;
    font-weight: 600;
  }
`;

export const PayInfo = styled(Flex)`
  background-color: #fff;
  border: 1px solid #372874;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;

  > h4 {
    font-weight: 600;
  }
`;

export const WarningInfo = styled(Box)`
  margin-bottom: 20px;
  font-size: 0.95rem;
  line-height: 27px;

  > p > span {
    font-weight: 600;
  }
`;
