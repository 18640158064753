import React, { useState } from "react";
import axios from "axios";
import Loading from "Shared/Loading";
import { Box, Flex, Heading, useToast } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { LoginLink, RegTitle } from "Styles/ComponentStyled";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import * as server from "Config/server";
import Layout from "Common/Layout";

const RegBtn = styled.button`
  background-color: #ffce1f;
  color: #444;
  padding: 10px 15px;
  outline: 0;
  border: 1px solid #ffce1f;
  margin: 25px 0 15px 0;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  transition: all 300ms ease;

  &:hover {
    font-weight: 600;
    color: #fff;
    background-color: #ff9300;
    border: 1px solid #ff9300;
  }
`;

const EmailSignUp = () => {
  const Toast = useToast();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [input, SetInput] = useState({
    email: "",
    password: "",
    checkPassword: "",
    name: "",
  });

  const [validMessage, SetMessage] = useState({
    emailMessage: "",
    passwordValidMessage: "특수문자,숫자 포함(최대 20자)",
    checkMessage: "",
  });

  const [isEmail, setIsEmail] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [isCheckPassword, setIsCheckPassword] = useState(false);

  const { emailMessage, passwordValidMessage, checkMessage } = validMessage;
  const { email, password, checkPassword, name } = input;

  const onNameChange = (e) => {
    SetInput({ ...input, [e.target.name]: e.target.value });
  };

  const onEmailChange = (e) => {
    const emailRegex =
      /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

    let validEmail = e.target.value;

    if (!emailRegex.test(validEmail)) {
      SetMessage({
        ...validMessage,
        EmailMessage: "올바른 이메일 형식이 아닙니다.😭",
      });
      setIsEmail(false);
    } else {
      SetMessage({
        ...validMessage,
        EmailMessage: "올바른 이메일 형식이에요!👍",
      });
      SetInput({ ...input, RegEmail: validEmail });
      setIsEmail(true);
    }
  };

  const onPasswordChange = (e) => {
    const PasRegex =
      /^.*(?=^.{8,20}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/;

    let ValiPassword = e.target.value;

    if (!PasRegex.test(ValiPassword)) {
      SetMessage({
        ...validMessage,
        PasValiMessage: "올바른 비밀번호가 아닙니다.😭",
      });
      setIsPassword(false);
    } else {
      SetMessage({ ...validMessage, PasValiMessage: "올바른 비밀번호에요!👍" });
      SetInput({ ...input, RegPassword: ValiPassword });
      setIsPassword(true);
    }
  };

  const onCheckPwChange = (e) => {
    let CheckPw = e.target.value;

    if (CheckPw !== password) {
      SetMessage({
        ...validMessage,
        CheckMessage: "비밀번호가 일치하지 않습니다😭",
      });
      setIsCheckPassword(false);
    } else {
      SetMessage({ ...validMessage, CheckMessage: "비밀번호가 일치합니다!👍" });
      SetInput({ ...input, CheckPassword: checkPassword });
      setIsCheckPassword(true);
    }
  };

  const onGoSignup = async (e) => {
    e.preventDefault();

    if (!isEmail || !isPassword || !isCheckPassword || !name) {
      Toast({
        position: "top-right",
        title: "Fail",
        description: "빈 칸이 있거나 유효하지 않은 정보가 있습니다!",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    setLoading(true);
    const auth = getAuth();
    auth.languageCode = "ko";

    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    ).catch((error) => {
      const errorMessage = error.message;
      const errorIndex = errorMessage.indexOf("email-already-in-us");

      if (errorIndex !== -1) {
        Toast({
          position: "top-right",
          title: "Fail",
          description: "이미 누군가 쓰고 있는 이메일 입니다.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
      } else {
        Toast({
          position: "top-right",
          title: "Fail",
          description: errorMessage,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
      }
    });

    await updateProfile(userCredential.user, {
      displayName: name,
    }).catch(() => {
      setLoading(false);
    });

    await axios({
      method: "post",
      url: `${server.SERVER_URL}/user/signup`,
      data: {
        provider: "firebase",
        token: userCredential.user.accessToken,
      },
    }).catch((error) => {
      setLoading(false);
      if (error.response.data.errorCode === 108) {
        Toast({
          position: "top-right",
          title: "Fail",
          description: "이미 가입된 유저 또는 가입 불가능한 정보입니다.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
      }
    });

    await sendEmailVerification(auth.currentUser).catch(() => {
      setLoading(false);
      setTimeout(
        Toast({
          position: "top-right",
          title: "Fail",
          description: "메일이 발송되지 않았습니다.",
          status: "error",
          duration: 3000,
          isClosable: true,
        }),
        3000
      );
    });

    setLoading(false);
    navigate("/welcome");

    setTimeout(
      Toast({
        position: "top-right",
        title: "성공!",
        description: "인증 메일이 발송되었습니다.",
        status: "success",
        duration: 3000,
        isClosable: true,
      }),
      3000
    );
  };

  return (
    <>
      {isLoading && <Loading />}
      <Layout>
        <Box p={{ base: "5em 2em", md: "8em 5em" }} bg="#f9f9f9">
          <Box maxW="350px" m="0 auto">
            <Box mb="30px">
              <Link to="/signup">
                <ArrowBackIcon w={6} h={6} />
              </Link>
            </Box>
            <RegTitle direction={"column"}>
              <Heading as="h3" size="md">
                이메일로 회원가입
              </Heading>
              <Box m="25px 0">
                <Heading as="h5">
                  <span style={{ color: "red" }}>*</span> 회원가입과 동시에
                  인증용 메일이 전송됩니다.
                </Heading>
                <Heading as="h5">
                  <span style={{ color: "red" }}>*</span> 반드시 유효한 메일
                  주소로 가입해주세요!
                </Heading>
              </Box>
            </RegTitle>
            <form>
              <Flex direction={"column"} gap="5px">
                <Box>
                  <label className="labelFlex">
                    이름
                    <input
                      className="signInInput"
                      placeholder="이름"
                      required
                      name="RegName"
                      onChange={onNameChange}
                      onFocus={onNameChange}
                    />
                  </label>
                </Box>
                <Box>
                  <label className="labelFlex">
                    이메일
                    <input
                      className="signInInput"
                      placeholder="E-mail"
                      required
                      name="RegEmail"
                      onChange={onEmailChange}
                      onFocus={onEmailChange}
                    />
                  </label>
                  <p className={isEmail ? "RegCorrect" : "RegIncorrect"}>
                    {emailMessage}
                  </p>
                </Box>
                <Box>
                  <label className="labelFlex">
                    비밀번호
                    <input
                      className="signInInput"
                      type="password"
                      placeholder="password"
                      required
                      name="RegPassword"
                      onChange={onPasswordChange}
                      onFocus={onPasswordChange}
                    />
                  </label>
                  <p className={isPassword ? "RegCorrect" : "RegIncorrect"}>
                    {passwordValidMessage}
                  </p>
                </Box>
                <Box mb="8px">
                  <label className="labelFlex">
                    비밀번호 확인
                    <input
                      className="signInInput"
                      type="password"
                      placeholder="password"
                      required
                      name="CheckPassword"
                      onChange={onCheckPwChange}
                      onFocus={onCheckPwChange}
                    />
                  </label>
                  <p
                    className={isCheckPassword ? "RegCorrect" : "RegIncorrect"}
                  >
                    {checkMessage}
                  </p>
                </Box>
              </Flex>
              <div className="isChecked">
                <p>
                  <a
                    href="https://appplatform.notion.site/8be8232fff0341799cf8c13728610b6b"
                    target="_blank"
                    rel="noreferrer"
                  >
                    이용약관
                  </a>
                  과&nbsp;
                  <a
                    href="https://www.notion.so/appplatform/d99f247a66d141bbbdf227739861a0a2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    개인정보처리방침
                  </a>
                  을 확인하였고&nbsp;동의합니다.
                </p>
              </div>
              <RegBtn type="submit" onClick={onGoSignup}>
                회원가입
              </RegBtn>
            </form>
            <LoginLink>
              드로잉젤 회원이신가요? <Link to="/login">로그인</Link>
            </LoginLink>
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default EmailSignUp;
