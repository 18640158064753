import { useEffect } from "react";
import styled from "styled-components";
import { Box, Flex, Heading } from "@chakra-ui/react";
import {
  CheckCircleIcon,
  InfoOutlineIcon,
  SmallCloseIcon,
  WarningTwoIcon,
} from "@chakra-ui/icons";

const ToastWrapper = styled(Box)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 80px;
  z-index: 99;
`;

const ToastContainer = styled.div`
  position: sticky;
  display: flex;
  justify-content: flex-end;
  top: 10px;
  margin-right: 10px;
  transition: transform 0.6s ease-in-out;
  animation-name: toast-in-right;
  animation-duration: 0.5s;

  cursor: pointer;

  @keyframes toast-in-right {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
`;

const ToastBox = styled(Box)`
  width: 280px;
  padding: 12px 10px;
  border-radius: 5px;
  word-break: keep-all;
`;

const Description = styled.p`
  padding-left: 20px;
  padding-right: 5px;
  padding-top: 5px;
`;

export function Toastify({ category, title, description, open, close }) {
  useEffect(() => {
    if (open) {
      setTimeout(close, 3000);
    }
  }, [open]);

  return (
    <>
      {open && (
        <ToastWrapper>
          <ToastContainer onClick={close}>
            <ToastBox className={`toast-${category}`}>
              <Flex align="center" justify={"space-between"}>
                <Flex align="center" gap={"6px"}>
                  {category === "success" && <CheckCircleIcon />}
                  {category === "error" && <InfoOutlineIcon />}
                  {category === "warning" && <WarningTwoIcon />}
                  {category === "info" && <InfoOutlineIcon />}
                  <Heading as="h4" size="sm">
                    {title}
                  </Heading>
                </Flex>
                <SmallCloseIcon
                  onClick={close}
                  className="alertClose"
                  w={5}
                  h={5}
                />
              </Flex>
              <Description>{description}</Description>
            </ToastBox>
          </ToastContainer>
        </ToastWrapper>
      )}
    </>
  );
}

export default Toastify;
