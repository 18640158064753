//회원 가입 후 바로 보이는 페이지
//provider에 따라 달라지게?

import React from "react";
import { Link } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import styled from "styled-components";
import { DoneButton, DoneContainer } from "Styles/ComponentStyled";
import Layout from "Common/Layout";

const ImgBox = styled.div`
  text-align: center;

  > img {
    width: 150px;
  }
`;

const DoneText = styled(Box)`
  text-align: center;
  word-break: keep-all;

  > h2 {
    font-size: 35px;
    font-weight: 900;
    margin-bottom: 15px;
  }

  > p {
    font-size: 18px;
    line-height: 30px;
  }

  @media screen and (max-width: 480px) {
    > h2 {
      font-size: 1.8rem;
    }

`;

const Welcome = () => {
  return (
    <Layout>
      <DoneContainer direction="column" justify="center" align="center">
        <Flex direction={"column"} align="center" gap="30px">
          <DoneText>
            <h2>Welcome! 🥳</h2>
            <p>드로잉젤 멤버가 되신 걸 환영해요!</p>
          </DoneText>
          <DoneButton
            direction={{ base: "column", md: "row" }}
            align={{ base: "auto", md: "center" }}
          >
            <button>
              <Link to="/">서비스 홈</Link>
            </button>
            <button>
              <Link to="/login">로그인 하기</Link>
            </button>
          </DoneButton>
        </Flex>
      </DoneContainer>
    </Layout>
  );
};

export default Welcome;
