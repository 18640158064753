import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Flex, Heading } from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";

import { useRecoilValue } from "recoil";
import { ProfileMembership, ProfileUser } from "Config/recoil";

import dayjs from "dayjs";

import styled from "styled-components";
import "Styles/Main.scss";
import { InfoBox } from "Styles/ComponentStyled";
import Layout from "./Layout";

const BtnBox = styled.div`
  width: 100%;
  text-align: center;
  padding: 30px 0;
`;

const Back = styled.button`
  background-color: #b8c6db;
  padding: 5px 10px;
  border: 1px solid #b8c6db;
  border-radius: 5px;
  margin-right: 10px;
`;

const Payment = () => {
  const navigate = useNavigate();
  const userdata = JSON.parse(sessionStorage.getItem("UserData"));
  const user = useRecoilValue(ProfileUser);
  const membership = useRecoilValue(ProfileMembership);

  const [Userprofile, SetProfile] = useState({
    userName: "",
    userEmail: "",
  });

  const { userName, userEmail } = Userprofile;

  useEffect(() => {
    if (userdata && userdata.provider === "password") {
      SetProfile({
        ...Userprofile,
        userName: userdata.displayName,
        userEmail: userdata.email,
      });
    }
    if (userdata && userdata.provider !== "password") {
      SetProfile({
        ...Userprofile,
        userName: userdata.userName,
        userEmail: userdata.email,
      });
    }
  }, []);

  return (
    <Layout>
      <Box p={{ base: "5em 2em", md: "8em 5em" }} bg="#f3f2f7">
        {/* 유저 Info */}
        <Flex direction={"column"} w="100%" maxW="800px" m="0 auto">
          <Box bg='#fff' border='1px solid #444'>
            <Heading as="h4" size="lg" margin="15px 0 15px 15px">
              <InfoOutlineIcon /> Receipt
            </Heading>
            <InfoBox>
              <h4>이름</h4>
              <p>{userName}</p>
            </InfoBox>
            <InfoBox>
              <h4>아이디</h4>
              <p>{userEmail}</p>
            </InfoBox>
            <InfoBox>
              <h4>가입날짜</h4>
              <p>
                {userdata && `${dayjs(user.create_at).format("YYYY-MM-DD")}`}
              </p>
            </InfoBox>
            <InfoBox>
              <h4>결제상품</h4>
              <p>
                {/* 구독 x */}
                {membership.bill_service === "none" &&
                  "멤버십을 구독하지 않은 회원입니다."}
                {/* 구독 중 */}
                {membership.bill_service !== "none" &&
                  membership.current > 0 &&
                  `${membership.current}개월`}

                {/* 구독 후 해지 */}
                {membership.bill_service !== "none" &&
                  membership.current === 0 &&
                  membership.before > 0 &&
                  `${membership.before}개월`}

                {/* 구독취소시 구독 취소 문구 표시 */}
                {user.membership_cancel && (
                  <span className="Disabled">구독취소</span>
                )}
              </p>
            </InfoBox>

            <InfoBox>
              <h4>결제금액</h4>
              <p>
                {membership.bill_service === "none" && "없음"}
                {membership.current === 0 &&
                  membership.before === 1 &&
                  "25,000원"}
                {membership.current === 0 &&
                  membership.before === 3 &&
                  "60,000원"}
                {membership.current === 0 &&
                  membership.before === 6 &&
                  "90,000원"}
                {membership.current === 1 && "25,000원"}
                {membership.current === 3 && "60,000원"}
                {membership.current === 6 && "90,000원"}
              </p>
            </InfoBox>
            <InfoBox>
              <h4>이용기간</h4>
              <p>
                {/* 구독한 적 있음 / 최근 결제일 없음 */}
                {membership.bill_service !== "none" &&
                  user.membership_recent_date === null &&
                  `${dayjs(membership.start_date).format(
                    "YYYY-MM-DD"
                  )} ~ ${dayjs(membership.next_date).format("YYYY-MM-DD")}`}

                {/* 구독한 적 있음 / 최근 결제일 있음  */}
                {membership.bill_service !== "none" &&
                  user.membership_recent_date !== null &&
                  `${dayjs(user.membership_recent_date).format(
                    "YYYY-MM-DD"
                  )} ~ ${dayjs(membership.next_date).format("YYYY-MM-DD")}`}
              </p>
            </InfoBox>

            <InfoBox>
              <h4>결제 수단</h4>
              <p>
                {membership.bill_service === "none" &&
                  "멤버십을 구독하지 않은 회원입니다."}
                {membership.bill_service === "iamport" && "카카오페이"}
                {membership.bill_service === "kakao" && "카카오페이"}
                {membership.bill_service === "inicis" && "신용/체크카드 결제"}
                {membership.bill_service === "innopay" && "신용카드/체크카드"}
                {membership.bill_service === "nopassbook" && "무통장"}
              </p>
            </InfoBox>
          </Box>
        </Flex>
        <BtnBox>
          <Back
            onClick={() => {
              navigate(`/mypage`);
            }}
          >
            뒤로 가기
          </Back>
        </BtnBox>
      </Box>
    </Layout>
  );
};

export default Payment;
