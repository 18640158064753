//회원가입 페이지
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Box, Flex, Heading, useToast } from "@chakra-ui/react";
import styled from "styled-components";
import { FacebookOption, Google, MailOption } from "grommet-icons";
import Loading from "Shared/Loading";
import "Styles/Main.scss";
import { LoginLink, RegTitle } from "Styles/ComponentStyled";
import { browserSessionPersistence, setPersistence } from "firebase/auth";
import { authService, firebaseInstance } from "Config/FirebaseConfig";
import * as server from "Config/server";
import Layout from "Common/Layout";

const Line = styled.div`
  height: 1px;
  width: 50%;
  border-bottom: 1px solid #aaa;
`;

const Signup = () => {
  const { Kakao } = window;
  const navigate = useNavigate();
  const Toast = useToast();

  const [isLoading, setLoading] = useState(false);
  const [isInApp, SetInApp] = useState(false);

  const LoginKakao = () => {
    Kakao.Auth.authorize({
      redirectUri: "https://drawingel.com/oauth",
    });
  };

  const SignInGoogle = async () => {
    setLoading(true);
    let provider = new firebaseInstance.auth.GoogleAuthProvider();

    await setPersistence(authService, browserSessionPersistence).catch(() => {
      setLoading(false);
    });

    const dataGoogle = await authService
      .signInWithPopup(provider)
      .catch((error) => {
        setLoading(false);
        if (error.code === "auth/account-exists-with-different-credential") {
          Toast({
            position: "top-right",
            title: "Fail",
            description:
              "이미 페이스북으로 로그인했던 계정입니다. 동일한 이메일 주소를 사용하여 여러 계정을 만들 수 없습니다.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          setLoading(false);
        }
      });

    let credential = dataGoogle.credential;
    let user = dataGoogle.user;
    let providerId = credential.providerId;
    let email = user.email;
    let create = user.metadata.creationTime;
    let username = user.displayName;
    let userPhoto = user.photoURL;

    await FirebaseSignup(email, create, providerId, username, userPhoto);
  };

  const SignInFacebook = async () => {
    setLoading(true);
    let provider = new firebaseInstance.auth.FacebookAuthProvider();

    await setPersistence(authService, browserSessionPersistence).catch(() => {
      setLoading(false);
    });

    const dataFacebook = await authService
      .signInWithPopup(provider)
      .catch((error) => {
        setLoading(false);
        if (error.code === "auth/account-exists-with-different-credential") {
          Toast({
            position: "top-right",
            title: "Fail",
            description:
              "이미 구글로 로그인했던 계정입니다. 동일한 이메일 주소를 사용하여 여러 계정을 만들 수 없습니다.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          setLoading(false);
        }
      });

    let user = dataFacebook.user;
    let providerId = dataFacebook.credential.providerId; //facebook.com
    let email = user.email;
    let create = user.metadata.creationTime;
    let username = user.displayName;
    let userPhoto = user.photoURL;

    await FirebaseSignup(email, create, providerId, username, userPhoto);
  };

  const FirebaseSignup = async (
    providerId,
    email,
    create,
    username,
    userPhoto
  ) => {
    const token = await authService.currentUser.getIdToken();

    await axios({
      method: "post",
      url: `${server.SERVER_URL}/user/signup`,
      data: {
        provider: "firebase",
        token: token,
      },
    }).catch((error) => {
      if (error.response.status === 403) {
        Toast({
          position: "top-right",
          title: "Fail",
          description: "이미 가입된 유저 또는 가입 불가능한 정보입니다.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
      }
      if (error.response.status === 500) {
        Toast({
          position: "top-right",
          title: "Fail",
          description: `${error.message}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
      }
    });

    const userData = {
      isLogin: true,
      email: email,
      create: create,
      provider: providerId,
      userName: username,
      userImage: userPhoto,
    };

    await TokenLogin(token, userData);
  };

  //signup -> login
  const TokenLogin = async (token, userData) => {
    const response = await axios({
      method: "post",
      url: `${server.SERVER_URL}/user/login`,
      data: {
        provider: "firebase",
        token: token,
      },
    }).catch(async (error) => {
      if (error.response.status === 403) {
        sessionStorage.clear();
        Toast({
          position: "top-right",
          title: "Fail",
          description: "존재하지 않은 유저입니다.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    });

    sessionStorage.setItem("UserData", JSON.stringify(userData));
    sessionStorage.setItem("token", response.data.data.token);
    setLoading(false);
    //await getProfile(token);
    setTimeout(() => navigate("/", { replace: true }), 3000);
  };

  useEffect(() => {
    let userAgent = navigator.userAgent;
    let check = userAgent.indexOf("KAKAOTALK");
    let checkNaver = userAgent.indexOf("NAVER");
    let checkInsta = userAgent.indexOf("Instagram");
    let checkFB = userAgent.indexOf("FB");

    if (
      check !== -1 ||
      checkNaver !== -1 ||
      checkInsta !== -1 ||
      checkFB !== -1
    ) {
      SetInApp(true);
    } else {
    }
  }, [isInApp]);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      <Layout>
        <Box p={{ base: "5em 2em", md: "8em 5em" }} bg="#f9f9f9">
          <Box maxW="350px" m="0 auto">
            <RegTitle direction={"column"}>
              <Heading as="h3" size="md">
                회원가입
              </Heading>
              <Heading as="h4" size="sm">
                가입 하시면 더 많은 서비스를 즐기실 수 있어요!
              </Heading>
            </RegTitle>

            <Box textAlign="center" m="40px 0 15px 0">
              <Flex direction={"column"} align="center" justify={"center"}>
                {!isInApp && (
                  <button
                    className="googleButton"
                    name="Google"
                    onClick={SignInGoogle}
                  >
                    <Google color="plain" /> 구글로 시작하기
                  </button>
                )}
                <button
                  id="kakao-login-btn"
                  className="kakaoButton"
                  name="kakao"
                  onClick={LoginKakao}
                >
                  <img src="/kakao_symbol.png" alt="kakao" />
                  <span>카카오로 시작하기</span>
                </button>
                <button
                  className="facebookButton"
                  name="Facebook"
                  onClick={SignInFacebook}
                >
                  <FacebookOption color="plain" /> 페이스북로 시작하기
                </button>
                <Link to="/signup/email" style={{ width: "100%" }}>
                  <button className="EmailButton">
                    <MailOption color="#444" /> 이메일로 시작하기
                  </button>
                </Link>
              </Flex>
            </Box>

            <Box textAlign="center" m="15px 0">
              <Flex justify={"center"} align="center">
                <Line></Line>
                <p className="orLink">or</p>
                <Line></Line>
              </Flex>
            </Box>
            <LoginLink>
              드로잉젤 회원이신가요? <Link to="/login">로그인</Link>
            </LoginLink>
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default Signup;
